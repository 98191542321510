import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomModal from '../../components/modal/modal'; // Import the modal component
import ArrowBackIcon from '../../images/Square Alt Arrow Left.png';
import { useHistory, useParams } from 'react-router-dom'; // Assuming you are using React Router
import {Box,Paper, Typography ,Toolbar ,Stack, TextField, Button, Grid, Select, MenuItem, CircularProgress, Alert } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { frFR } from '@mui/x-date-pickers/locales';
import "dayjs/locale/fr";
import moment from 'moment';
import Container from '@material-ui/core/Container';

const SurveyUpdateComponent = () => {
  const history = useHistory();
  const { surveyIdParam } = useParams(); // Assuming you are using React Router
  const [openModal, setOpenModal] = React.useState(null);
  const [errors, setErrors] = useState({});
  const [loadingsurvey ,setloadingsurvey] =useState(false);
  const [error,seterror] =useState("")
  const [loading,setloading] =useState(false);
  const [enumData, setEnumData] = useState({
    USER_GENDER:[],
    MARITAL_STATUS:[],
    NATIONNALITIES:[],
    CITY:[],
    COMMUNE:[],
    COUNTRIES:[],
    OCCUPATION:[],
    SECTOR_OF_ACTIVITY:[],
    JOB_CATEGORY:[],
    CATEGORY_TYPE:[]
  });
  // State to hold the selected survey data
  const [surveyData, setSurveyData] = useState({
    _id: '',
    title: '',
    description: '',
    points: '',
    questions: '',
    response_time:"",
    start_at: '',
    end_at: '',
    participants: [],
    urlLimeSurvey: '',
    targeting: {
      minAge: '',
      maxAge: '',
      gender: [],
      countries: [],
      nationalities: [],
      cities: [],
      communities: [],
      categories: [],
      jobs: [],
      jobsCategories: [],
      marital_status: [],
    },
  });

// Function to fetch survey data
const fetchSurveyData = async () => {
  try {
    console.log(surveyIdParam)
    setloadingsurvey(true)
    const response = await axios.get('surveys/'+surveyIdParam);
    
    if (response.data ) {
     
        setSurveyData(response.data.survey);
        seterror(response.data.error)
      
    } else {
      console.error('Invalid or empty survey data format:', response.data);
    }
  } catch (error) {
    console.error('Error fetching survey data:', error);
  }
  setloadingsurvey(false)
};

  //fetch ENUM data
  const fetchEnumData = async () => {
    try {
      const response = await axios.get('surveys/targetingLists');
      setEnumData(response.data);
    } catch (error) {
      console.error('Error fetching enum data:', error.message);
    }
  }

  // Function to update survey data
  const updateSurveyData =  async () => {
     // Validation: Check if all required fields are filled
     const requiredFields = [
      'title',
      'description',
      'points',
      'response_time',
      'urlLimeSurvey',
    ];
    const newErrors = {};
    requiredFields.forEach((field) => {
      const fieldValue = surveyData[field];
      if (!surveyData[field] || (Array.isArray(surveyData[field]) && surveyData[field].length === 0)) {
        newErrors[field] = 'Ce champ est obligatoire';
      }else {
        // Check the type of the field based on the required type
        switch (field) {
          case 'title':
          case 'description':
            if (typeof fieldValue !== 'string') {
              newErrors[field] = 'Ce champ doit être une chaîne de caractères';
            }
            break;
          case 'points':
          case 'response_time':
            if (isNaN(fieldValue)) {
              newErrors[field] = 'Ce champ doit être un nombre';
            }
            break;
          case 'url':
            if (typeof fieldValue !== 'string') {
              newErrors[field] = 'Ce champ doit être une URL valide';
            }
            break;
          // Add more cases for other fields if needed
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const errorMessage = Object.values(newErrors).join('\n');
      handleCloseModal();
      return;
    }
    setloading(true)
    try {
      surveyData.participants=undefined;
      await  axios.patch(`surveys/update/${surveyIdParam}`, surveyData);
      // Optionally, you can handle success or show a notification here
      handleCloseModal();
      history.go(0);
    } catch (error) {
      console.error('Error updating survey data:', error);
    }
    setloading(false)
  };

  
  const handleDeleteSurvey = () => {
    setloading(true)
    axios.delete(`surveys/remove/${surveyIdParam}`)
      .then((response) => {
        setloading(false)
        handleCloseModal();
        history.push(`/SurveyList`);
      })
      .catch((error) => {
        setloading(false)
        alert('Error deleting user:', error);
        handleCloseModal();
      });
  };

  // Handler function for updating nested data
  const handleTargetingChange = (key, value) => {
    setSurveyData((prevData) => ({
      ...prevData,
      targeting: {
        ...prevData.targeting,
        [key]: Array.isArray(value) ? value : [value], // Ensure value is always an array
      },
    }));
  };

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const isModalOpen = (modalName) => {
    return openModal === modalName;
  };
  const handleBackClick = () => {
    history.goBack();
  };
  const handleDateChange = (date, fieldName) => {
    setSurveyData({ ...surveyData, [fieldName]: date });
  };
  // Fetch survey data on component mount or when surveyIdParam changes
  useEffect(() => {
    fetchSurveyData();
    fetchEnumData();
  }, [surveyIdParam]);

  return (
    <Container className="container">
      { error &&<Alert style={{marginBottom:10}} severity="error">{error}</Alert>}
    <Toolbar>
      <Grid container >
        <Grid item xs={6}>
 
        <h2 className="Username_title">
          <img className="Back" src={ArrowBackIcon} alt="Back"  style={{ cursor: 'pointer'}} onClick={handleBackClick} />
          {surveyData.title}
          </h2>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1} style={{float:"right",display:'flex',alignItems:'center' }}>
          {loadingsurvey && <CircularProgress  style={{marginRight:10}} color='primary' size={"2rem"}/>}

            <Button disabled={loading} style={{ backgroundColor:'#E0F2ED', height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }} onClick={() => handleOpenModal('updateSurvey')}>
              Sauvegarder
              {(loading ) && <CircularProgress  style={{marginLeft:10}} color='primary' size={"1rem"}/>}

            </Button>

            <Button style={{backgroundColor:'#E0F2ED', height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }} onClick={() => handleOpenModal('removeSurvey')} >
              Supprimer
            </Button>
            <Button style={{ backgroundColor:'#E0F2ED' ,height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }} onClick={() => history.push(`/participants/${surveyIdParam}`)}>
            Liste des répondants ({surveyData?.participants || 0})
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
    {/* SURVEY INFO START */}
   {/* SURVEY INFO START */}
   <Box sx={{ paddingBottom:2}}>
     <Paper sx={{ padding: 2, borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography  variant="body1" className="myTypography">Informations générales</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography  variant="body1" className="InputTag">Nom de l‘enquête</Typography>
            <TextField disabled required error={Boolean(errors.title)} helperText={errors.title} fullWidth value={surveyData.title} onChange={(e) => setSurveyData({ ...surveyData, title: e.target.value })}/>
            </Grid>
            <Grid item xs={8}>
            <Typography variant="body1" className="InputTag">Description</Typography>
            <TextField required error={Boolean(errors.description)} helperText={errors.description}  fullWidth value={surveyData.description} onChange={(e) => setSurveyData({ ...surveyData, description: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Points à gagner</Typography>
            <TextField required error={Boolean(errors.points)} helperText={errors.points}  fullWidth value={surveyData.points} onChange={(e) => setSurveyData({ ...surveyData, points: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Nombre de questions</Typography>
            <TextField disabled error={Boolean(errors.questions)} helperText={errors.questions}  fullWidth value={surveyData.questions} onChange={(e) => setSurveyData({ ...surveyData, questions: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Date de publication</Typography>
            <TextField disabled error={Boolean(errors.start_at)} helperText={errors.start_at}  fullWidth value={
              moment(surveyData.start_at).format('DD/MM/YYYY - HH:MM')
            } onChange={(e) => setSurveyData({ ...surveyData, start_at: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Date de fin de l‘enquête</Typography>
            <TextField disabled error={Boolean(errors.end_at)} helperText={errors.end_at}  fullWidth value={
         moment(surveyData.end_at).format('DD/MM/YYYY - HH:MM')
              } onChange={(e) => setSurveyData({ ...surveyData, end_at: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Temps de réponse (en minutes)</Typography>
            <TextField required error={Boolean(errors.response_time)} helperText={errors.response_time}  fullWidth value={surveyData.response_time} onChange={(e) => setSurveyData({ ...surveyData, response_time: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">URL de l‘enquête</Typography>
            <TextField disabled error={Boolean(errors.urlLimeSurvey)} helperText={errors.urlLimeSurvey}  fullWidth value={surveyData.urlLimeSurvey} onChange={(e) => setSurveyData({ ...surveyData, urlLimeSurvey: e.target.value })}/>
            </Grid>
                  {/* **** */}
          <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Client</Typography>
            <TextField   fullWidth value={surveyData.publisher} onChange={(e) => setSurveyData({ ...surveyData, publisher: e.target.value })}/>
         </Grid>
      <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Type de catégorie</Typography>
            <Select required  fullWidth  value={surveyData ? (surveyData.tag || []) : []}   onChange={(e) => setSurveyData({ ...surveyData, tag: e.target.value })} >
          {enumData.CATEGORY_TYPE?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
      </Grid>
      {/* **** */}
      <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Nom de la catégorie</Typography>
            <TextField required error={true}  fullWidth value={surveyData.tagTitle} onChange={(e) => setSurveyData({ ...surveyData, tagTitle: e.target.value.substring(0, 35)  })}/>
            <Typography variant="caption" color="textSecondary">Maximum 35 caractères</Typography>
      </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box sx={{ width: '100%', height: '100%', position: 'relative', background: 'white' , paddingBottom:2}}>
     <Paper elevation={3} sx={{ padding: 2, background: 'rgba(255, 255, 255, 0.60)', borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
      {/* **** */}
            <Grid item xs={12}>
            <Typography  variant="body1" className="myTypography">Ciblage</Typography>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Âge minimum</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="fr"  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
  <DatePicker
    onChange={(date) => handleDateChange(date)}
    defaultValue={surveyData.maxAge}
    renderInput={(params) => (
      <TextField
        {...params}
        fullWidth
        required
        error={Boolean(errors.maxAge)}
        helperText={errors.maxAge}
        label="Âge maximum"
      />
    )}
    format="DD/MM/YYYY" // Set the date format to "DD/MM/YYYY"
  />
</LocalizationProvider>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Âge maximum</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr"  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
        <DatePicker
          value={surveyData.maxAge}
          onChange={(date) => handleDateChange(date)}
          renderInput={(params) => (
            <TextField {...params} fullWidth required error={Boolean(errors.maxAge)} helperText={errors.maxAge} />
          )}
        />
      </LocalizationProvider>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Sexe</Typography>
            <Select required multiple fullWidth value={surveyData.targeting ? surveyData.targeting.gender : []} onChange={(e) => handleTargetingChange('gender', e.target.value)} >
          {enumData.USER_GENDER?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
            </Grid>
                  {/* **** */}
                  <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Etat civil</Typography>
            <Select required multiple fullWidth value={surveyData?.targeting ? surveyData.targeting.marital_status : []} onChange={(e) => handleTargetingChange('marital_status', e.target.value)} >
          {enumData.MARITAL_STATUS?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Nationalité</Typography>
            <Select required multiple fullWidth  value={surveyData.targeting ? (surveyData.targeting.nationalities || []) : []}onChange={(e) => handleTargetingChange('nationalities', e.target.value)} >
          {enumData.NATIONNALITIES?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
          <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Pays</Typography>
            <Select required multiple fullWidth value={surveyData.targeting ? surveyData.targeting.countries : []} onChange={(e) => handleTargetingChange('countries', e.target.value)} >
          {enumData.COUNTRIES?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Ville</Typography>
            <Select required multiple fullWidth  value={surveyData.targeting ? (surveyData.targeting.cities || []) : []} onChange={(e) => handleTargetingChange('cities', e.target.value)} >
          {enumData.CITY?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Commune</Typography>
            <Select multiple fullWidth value={surveyData.targeting ? (surveyData.targeting?.communities || []) : []} onChange={(e) => handleTargetingChange('communities', e.target.value)} disabled={!surveyData.targeting || !surveyData.targeting.cities || surveyData.targeting.cities.length === 0}>
        {enumData.COMMUNE
      ?.filter((city) => surveyData.targeting && surveyData.targeting.cities.includes(city.name))
      ?.flatMap((city) =>
        city.data.map((commune) => (
          <MenuItem key={commune} value={commune}>
            {commune}
          </MenuItem>
        ))
      )}
  </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Métier</Typography>
            <Select required multiple fullWidth  value={surveyData.targeting ? (surveyData.targeting.jobs || []) : []}onChange={(e) => handleTargetingChange('jobs', e.target.value)} >
          {enumData.OCCUPATION?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Secteur d'activité</Typography>
            <Select required multiple fullWidth  value={surveyData.targeting ? (surveyData.targeting.jobsCategories || []) : []}onChange={(e) => handleTargetingChange('jobsCategories', e.target.value)} >
          {enumData.SECTOR_OF_ACTIVITY?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
            </Grid>



          </Grid>
        </Paper>
      </Box>
      {/* MODAL START */}
<CustomModal
        open={isModalOpen('removeSurvey')}
        handleClose={handleCloseModal}
        closeAfterTransition
        loading={loading}
        handleAction={handleDeleteSurvey}
        title="Supprimer l'enquete"
        content={`Êtes vous sur de vouloir supprimer l'enquete  ${surveyData?.title ?? 'No Title'}?`}
        button="Supprimer"
      />
<CustomModal
        open={isModalOpen('updateSurvey')}
        handleClose={handleCloseModal}
        closeAfterTransition
        handleAction={updateSurveyData}
        title="Sauvegarder l'enquete"
        content={`Êtes vous sur de vouloir sauvegarder l'enquete  ${surveyData?.title ?? 'No Title'}?`}
        button="Sauvegarder"
      />
{/* MODAL END */}
</Container>
  );
};

export default SurveyUpdateComponent;
